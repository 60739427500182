export default class RecuperoPassword{
    CodLang;
    CodShop;
    EmailRec;
    
    constructor(codLang,codShop,emailRec){
        this.CodLang = codLang;
        this.CodShop = codShop;
        this.EmailRec = Buffer.from(emailRec).toString("base64");
    }
}