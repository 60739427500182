<template>
    <section class="LoginSection">
        <img src="images/Poster.jpg" alt=""/>
        <div class="BackgroundOverlay">
			<div class="Container">
				<div class="Box">
					<div class="LoginLogo"><img src="/assets/images/LoginLogo.png" srcset="/assets/images/LoginLogo.png 2x" alt="" /></div>
					<h1>Non ricordi più la password?</h1>
					<div class="TxtEditor">
						<p></p>
					</div>
				</div>
				<div class="Box">
					<div class="Binder Big">
						<form class="Form PasswordRecoveryForm">
							<fieldset>
								<legend>Recupera password</legend>
								<div class="FormRow">
									<div class="FormBox66">
										<label>Username</label>
										<input type="email" placeholder="Inserisci la tua email" v-model="this.email">
									</div>
									<div class="FormBox33">
										<label>&nbsp;</label>
										<div class="Button">
											<input id="" name="" value="Recupera password"  @click="this.recuperaPassword()">
										</div>
									</div>
								</div>
								<div class="FormRow">
									<div class="FormBoxWide">
										<label class="PasswordRecovery"><a href="/">Torna alla pagina di login</a></label>
									</div>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
			</div>
        </div>                
    </section>
</template>
<script>

import AuthService from "@/services/auth.service.js";
import RecuperoPassword from "@/models/Utente/recuperoPassword.model.js";

export default {
    name: "RecuperoPassword",
    components:{
        Loader
    },
    data(){
        return {
            email: "",
            errorMsg: "",
            successMsg: ""
        }
    },
    watch:{
        email: function(newVal){
            if(newVal){
                this.errorMsg = "";
            }
        } 
    }, 
    methods: {
        recuperaPassword(){
            
            if(!this.email){
                this.errorMsg = "Email is required";
                return;
            }
            this.$store.dispatch({
                type: 'setLoader',
                value: true
            });
            let payload = new RecuperoPassword('IT','B2B', this.email);
            AuthService.recuperoPassword(payload,'b2b').then(
                (data) => {
                    this.$store.dispatch({
                        type: 'setLoader',
                        value: false
                    });
                    if(data.status == 200){
                        this.successMsg = "Password recovered successfully. An email has been sent to you with the new password";
                        this.$toast.success(this.successMsg);
                    }
                    else if(data.status == 400){
                        this.errorMsg = "The email entered is not present in our systems";
                        this.$toast.error(this.errorMsg);
                    }
                    else{
                        this.errorMsg = "An error occurred while retrieving the password";
                        this.$toast.error(this.errorMsg);
                    }
                }
            );
        }
    }
}
</script>

<style>
.errorMsg{
    color:#ff0000;
}
</style>